import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import { themeColor, useTheme } from "react-native-rapi-ui";
import TabBarIcon from "../components/utils/TabBarIcon";
import TabBarText from "../components/utils/TabBarText";
import dashboard from "../screens/admin/Dashboard";
import Orders from "../screens/admin/Orders";
import Processing from "../screens/admin/Processing";
import Completed from "../screens/admin/Completed";
import Cancellation from "../screens/admin/Cancellation";
import tblOrder from "../screens/admin/tblOrder";
import Cancelled from "../screens/admin/Cancelled";

const Tabs = createBottomTabNavigator();
const AdminTabs = () => {
  const { isDarkmode } = useTheme();
  return (
    <Tabs.Navigator
      screenOptions={{
        headerShown: false,
        tabBarStyle: {
          borderTopColor: isDarkmode ? themeColor.dark100 : "#c0c0c0",
          backgroundColor: isDarkmode ? themeColor.dark200 : "#FFD700",
        },
        tabBarLabelStyle: {
          fontWeight: "bold", // Make the text bold
          color: "#000", // Set the text color to black
        },
      }}
    >
      {/* these icons using Ionicons */}
      <Tabs.Screen
        name="ORDERS"
        component={Orders}
        options={{
          // tabBarLabel: ({ focused }) => (
          //   <TabBarText focused={focused} title="Order" />
          // ),
          tabBarIcon: ({ focused }) => (
            <TabBarIcon focused={focused} icon={"md-clipboard"} />
          ),
        }}
        
      />
       <Tabs.Screen
        name="PROCESSING"
        component={Processing}
        options={{
          // tabBarLabel: ({ focused }) => (
          //   <TabBarText focused={focused} title="Processing" />
          // ),
          tabBarIcon: ({ focused }) => (
            <TabBarIcon focused={focused} icon={"md-clipboard"} />
          ),
        }}
      />
       <Tabs.Screen
        name="COMPLETED"
        component={Completed}
        options={{
          // tabBarLabel: ({ focused }) => (
          //   <TabBarText focused={focused} title="Completed" />
          // ),
          tabBarIcon: ({ focused }) => (
            <TabBarIcon focused={focused} icon={"md-clipboard"} />
          ),
        }}
      />
        <Tabs.Screen
        name="CANCELLED"
        component={Cancelled}
        options={{
          // tabBarLabel: ({ focused }) => (
          //   <TabBarText focused={focused} title="Cancelled" />
          // ),
          tabBarIcon: ({ focused }) => (
            <TabBarIcon focused={focused} icon={"md-clipboard"} />
          ),
        }}
      />
      {/* <Tabs.Screen
            name="O R D E R S"
            component={tblOrder}
            options={{
              // tabBarLabel: ({ focused }) => (
              //   <TabBarText focused={focused} title="Order" />
              // ),
              tabBarIcon: ({ focused }) => (
                <TabBarIcon focused={focused} icon={"md-clipboard"} />
              ),
            }}
            
          /> */}
      <Tabs.Screen
        name="DASHBOARD"
        component={dashboard}
        options={{
          // tabBarLabel: ({ focused }) => (
          //   <TabBarText focused={focused} title="Dashboard" />
          // ),
          tabBarIcon: ({ focused }) => (
            <TabBarIcon focused={focused} icon={"grid-sharp"} />
          ),
        }}
      />
     
    </Tabs.Navigator>
  );
};

export default AdminTabs;
