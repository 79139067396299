import React, { useEffect, useState } from "react";
import { View, StyleSheet, Dimensions, ScrollView, TouchableOpacity,} from "react-native";
import { MainStackParamList } from "../../types/navigation";
import { AdminStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../../initSupabase";
import {
  Layout,
  Button,
  Text,
  TopNav,
  Section,
  SectionContent,
  useTheme,
  themeColor,
  SectionImage,
  TextInput,
  Picker,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";


export default function ({
  navigation,
}: NativeStackScreenProps<AdminStackParamList, "MenuAdd">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState(null)
  const [session, setSession] = useState<Session | null>(null);
 

  const [menuname, setMenuname] = useState("")
  const [price, setPrice] = useState("")
  const [Description, setDescription] = useState("")
 
  //const [priceTotal, setSetpricetotal] = useState()

  
    const Add_Menu = async () => {

      setLoading(true)
      const { data,error } = await supabase
      .from('Menu')
      .insert([{ menu_name:menuname,price:price,discription:Description,availability:"Available",Category:Category}])
    if (error) alert(error.message)
      setLoading(false)
    if(data){
      console.log(data)
      alert("Succesfull")
    }
    } 

    const [Category, setCategory] = useState(null);
    const items1 = [
        { label: 'Mains', value: 'Mains' },
        { label: 'Combos', value: 'Combos' },
        { label: 'Soup', value: 'Soup' },
        { label: 'Pansit', value: 'Pansit' },
        { label: 'Seafood Bilao', value: 'Seafood Bilao' },

    ];

  return (
    <Layout>
      <ScrollView>
      <TopNav
        middleContent="ADD A MENU"
        leftContent={
          <Ionicons
            name="chevron-back"
            size={20}
            color={isDarkmode ? themeColor.white100 : themeColor.dark}
          />
        }
        leftAction={() => navigation.goBack()}
       
      />
      <View // style={{ flex: 1, }}
      >
        <View style={styles.container}>
          <View style={styles.card}>
        <Section>
              <SectionContent  >
                    <Section  > 
                        <SectionContent style={styles.containerEDT}>
                        <Text fontWeight="bold" style={{ textAlign: "left" , marginBottom:5, marginTop:5,}}>  Menu Name:</Text>
                        <TextInput 
                          placeholder="Enter your Menu Name"
                          value={menuname}
                          onChangeText={(val) => setMenuname(val)}
                        />
                         <Text fontWeight="bold" style={{ textAlign: "left" , marginBottom:5,marginTop:5,}}>  Price:</Text>
                         <TextInput 
                          placeholder="Enter your Price"
                          value={price}
                          onChangeText={(val) => setPrice(val)}
                        />
                         <Text fontWeight="bold" style={{ textAlign: "left" , marginBottom:5,marginTop:5,}}>  Description:</Text>
                         <TextInput 
                          placeholder="Enter your Description"
                          value={Description}
                          onChangeText={(val) => setDescription(val)}
                        />
                          <Text fontWeight="bold" style={{ textAlign: "left" , marginBottom:5,marginTop:5,}}>  Description:</Text>
                         <TextInput 
                          placeholder="Enter your Description"
                          value={Description}
                          onChangeText={(val) => setDescription(val)}
                        />
                         <Text fontWeight="bold" >Category</Text>
                            <Picker
                            
                              items={items1}
                              value={Category}
                              placeholder="Choose Category"
                              onValueChange={(val) => setCategory(val)}
                          />
                        <Button text="Add" color='#FFD700' style={{marginTop: 20}} 
                        textStyle={{ color: "black",
                        textTransform: "uppercase", // Set text to uppercase
                      }}
                        onPress={()=> Add_Menu()}/>

                      </SectionContent>
                    
                    </Section>
            </SectionContent> 
            
        </Section>
        </View>
        </View>
      </View>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  singleItem: {
    flex: 1,
    flexDirection: "column",
    marginBottom: 10,
    backgroundColor: '#FFD700',
    Width: 100,
  },
  container: {
    flex: 1,
    alignItems: "center", // Center horizontally
    justifyContent: "center", // Center vertically
    backgroundColor: "", // Background color for the entire screen
  },
  card: {
    borderWidth: 10,
    borderColor: "#FFD700",
    backgroundColor: "white", // Background color for the card
    borderRadius: 10, // Border radius for the card
    shadowColor: "black", // Shadow color
    width: "90%",
    shadowOffset: {
      width: 10, // Increase the width to make the card larger
      height: 5, // Increase the height to make the card larger
    },
    shadowOpacity: 0.25, // Shadow opacity
    shadowRadius: 5, // Shadow radius
    elevation: 5, // Android shadow elevation
    marginBottom: 10,
      marginTop: 50,
      padding: 20, // Increase the padding to make the card larger
      alignItems: "center", // Center card content horizontally
      Width: 100,
    },
 
  });

