import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";


import AdminTabs from "./AdminTabs";
import MenuEdit from "../screens/admin/MenuEdit";
import Dashboard from "../screens/admin/Dashboard";
import MenuAdd from "../screens/admin/MenuAdd";
import OrderItem from "../screens/admin/OrderItem";
import Orders from "../screens/admin/Orders";
import Processing from "../screens/admin/Processing";
import Completed from "../screens/admin/Completed";
import ItemMenu from "../screens/admin/ItemMenu";
import InventoryMenu from "../screens/admin/InventoryMenu";
import InventoryAdd from "../screens/admin/InventoryAdd";
import Cancellation from "../screens/admin/Cancellation";
import Cancelled from "../screens/admin/Cancelled";
import tblOrder from "../screens/admin/tblOrder";
import tblOrderItem from "../screens/admin/tblOrderItem";
import CompleteInfo from "../screens/admin/CompleteInfo";
import Print from "../screens/admin/Print";
import Reciept from "../screens/admin/Reciept";


const AdminStack = createNativeStackNavigator();
const Admin = (menus:any) => {
  return (
    <AdminStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <AdminStack.Screen name="AdminTabs" component={AdminTabs} />
      <AdminStack.Screen name="MenuEdit" component={MenuEdit} initialParams={{ MenuID: menus.id }}/>
      <AdminStack.Screen name="Dashboard" component={Dashboard} />
      <AdminStack.Screen name="Processing" component={Processing} />
      <AdminStack.Screen name="Completed" component={Completed} />
      <AdminStack.Screen name="Cancellation" component={Cancellation} />
      <AdminStack.Screen name="Cancelled" component={Cancelled} />
      <AdminStack.Screen name="MenuAdd" component={MenuAdd} />
      <AdminStack.Screen name="Orders" component={Orders} />
      <AdminStack.Screen name="OrderItem" component={OrderItem} initialParams={{OrderID: menus.id}}/>
      <AdminStack.Screen name="ItemMenu" component={ItemMenu} />
      <AdminStack.Screen name="InventoryMenu" component={InventoryMenu} />
      <AdminStack.Screen name="InventoryAdd" component={InventoryAdd} />
      <AdminStack.Screen name="tblOrder" component={tblOrder}/>
      <AdminStack.Screen name="tblOrderItem" component={tblOrderItem}  initialParams={{OrderID: menus.id}}/>
      <AdminStack.Screen name="CompleteInfo" component={CompleteInfo}  initialParams={{OrderID: menus.id}}/>
      <AdminStack.Screen name="Print" component={Print}/>
      <AdminStack.Screen name="Reciept" component={Reciept}  initialParams={{OrderID: menus.id}}/>
      
    </AdminStack.Navigator>
  );
};

export default Admin;
