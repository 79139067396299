import React, { useEffect, useState } from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { AdminStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../../initSupabase";
import {
  Layout,
  Button,
  Text,
  TopNav,
  Section,
  SectionContent,
  useTheme,
  themeColor,
  SectionImage,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";




import { LogBox } from 'react-native';
import _ from 'lodash';

LogBox.ignoreLogs(['Warning:...']); // ignore specific logs
LogBox.ignoreAllLogs(); // ignore all logs
const _console = _.clone(console);
console.warn = message => {
if (message.indexOf('Setting a timer') <= -1) {
   _console.warn(message);
   }
};
export default function ({
  navigation,
}: NativeStackScreenProps<AdminStackParamList, "AdminTabs">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState(null)
  const [Orders, setOrders] = useState(null)
  
  useEffect(()=>{
    const fetchOders= async () => { 
    const {data, error} = await supabase
      .from('Oders')
      .select()
        .eq('status', 'Pending Cancel')
      if (error){ 
        setFetchError(null)
        setOrders(null)
      } 
      if (data){
        setOrders(data)
        setFetchError(null)
      }
    }
    fetchOders()
    }, [])



      const UpdateAO = async (menus:any) => {
        setLoading(true)
        const { data,error } = await supabase
        .from('Orders')
        .update([{ status: 'Cancelled'}])
        .eq('orderID', menus.order_ID)
      if (error) alert(error.message)
        setLoading(false)
      if(data){
        console.log(data)
        
      }
      }
     

  return (
    <Layout>
      <ScrollView>
      <TopNav
        middleContent="Cancellation Request"
        // rightContent={
        //   <Ionicons name="cart" size={24}  
        //   color={isDarkmode ? themeColor.white100 : themeColor.dark} />
        // }
        rightAction={() => {
          //navigation.navigate();
        }}
      />
      <View style={{flex: 1,}}>
        <Section>
              {Orders && ( 

              <SectionContent >
                  {Orders.map((menus:any) => (          
                    <Section  key={menus.id}> 
                        <SectionContent style={ styles.singleItem}>
                        <Text size='lg'>Order No: {menus.order_ID}</Text>
                        <Text size='xl'>Customer Name: {menus.CS_Name}</Text>
                        <Text size='lg'>Phone: {menus.PNumber}</Text>
                        <Text size='lg'>Email: {menus.Email}</Text>
                        <Text size='lg'>Reason: {menus.Reason}</Text>
                      </SectionContent>

                      <Button text="Approved" color='#ffa500' style={{marginTop: 5}} onPress={()=> UpdateAO(menus)}/>
                      <Button text="Declined"  status="danger" style={{marginTop: 5}} onPress={()=> UpdateD(menus)}/>
                    </Section>
                  ))}
            </SectionContent> )} 
          
        </Section>
       
      </View>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  singleItem: {
    flex: 1,
    flexDirection: "column",
    marginBottom: 10,
    backgroundColor: '#EEEEEE',
  }

 
  });
