import React, { useEffect, useState } from "react";
import { View, StyleSheet, Dimensions, ScrollView, TouchableOpacity,} from "react-native";
import { AdminStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../../initSupabase";
import {
  Layout,
  Button,
  Text,
  TopNav,
  Section,
  SectionContent,
  useTheme,
  themeColor,
  SectionImage,
  TextInput,
  Picker,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";
import { Route, RouteProp, useRoute } from "@react-navigation/native";


export default function ({
  navigation,
}: NativeStackScreenProps<AdminStackParamList, "OrderItem">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState(null)
  const [menu, setMenu] = useState(null)

  const route = useRoute<RouteProp<AdminStackParamList, "OrderItem">>();
  const { OrderID } = route.params;


  const [orderID, setOrderID] = useState(null)
  const [Name, setName] = useState(null)
  const [Pnumber, setPNUMBER] = useState()
  const [Address, setAddress] = useState(null)
  const [Orders, setOrders] = useState(null)
  const [Status, setStatus] = useState(null)
  const [totalPayment, setTotalPayment] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [GREFNo , setGREFNo] = useState(null)

  useEffect(()=>{
    const fetchItem = async () => { 
    const {data, error} = await supabase
      .from('Orders')
      .select()
      .eq('id', OrderID)
      .single()
      if (error?.message === 'Record not found'){ 
        setFetchError(null)
        setName(null)
        setAddress(null)
     
      } 
      if (data){
        setOrderID(data.orderID)
        setName(data.name)
        setPNUMBER(data.number)
        setAddress(data.address)
        setOrders(data.orders)
        setStatus(data.status)
        setTotalPayment(data.total_payment)
        setPaymentMethod(data.payment_method)
        setGREFNo(data.GcashRefNo)
        setFetchError(null)
        console.log(data)
      }
    }
    fetchItem()
    }, [OrderID])


    const Update_status = async () => {
      setLoading(true)
      const { data, error } = await supabase
      .from('Orders')
      .update({ status: Status })
      .eq('id', OrderID)
      setLoading(false)
    if(data){
      console.log(data)
      alert("Succesfull")
    }
    }
    console.log(route.params.OrderID)
    const items = [
      { label: 'Processing', value: 'Processing' },
      { label: 'Completed', value: 'Completed' },
  ];
    

 
  return (
    <Layout>
      <ScrollView>
      <TopNav
        middleContent="ORDER INFORMATION"
        leftContent={
          <Ionicons
            name="chevron-back"
            size={20}
            color={isDarkmode ? themeColor.white100 : themeColor.dark}
          />
        }
        leftAction={() => navigation.goBack()}
       
      />
      <View // style={{ flex: 1, }}
      >
        <Section>

              <SectionContent  >
                    <Section  > 
                        <SectionContent style={styles.containerEDT}>
                        <Text fontWeight="light" style={{ textAlign: "center" , marginBottom:5, marginTop:5,}}>  Order No. : {orderID}</Text>
                        <Text fontWeight="bold" style={{ textAlign: "center" , marginBottom:5, marginTop:5,}}>  Customer Name: {Name}</Text>
                        <Text fontWeight="bold" style={{ textAlign: "center" , marginBottom:5, marginTop:5,}}>  Mobile No. : {Pnumber}</Text>
                        <Text fontWeight="bold" style={{ textAlign: "center" , marginBottom:5, marginTop:5,}}>  Address : <Text fontWeight="light" style={{ textAlign: "left" , marginBottom:5, marginTop:5,}}>  {Address} </Text> </Text>
                        {/* <Text fontWeight="light" style={{ textAlign: "left" , marginBottom:5, marginTop:5,}}>  {Address} </Text> */}
                        <Text fontWeight="bold" style={{ textAlign: "center" , marginBottom:5, marginTop:5,}}>  Order : </Text> 
                        <Text fontWeight="light" style={{ width: "100%", textAlign: "center" , marginBottom:5, marginTop:5,}}>  {Orders} </Text>
                        {/* <Text fontWeight="light" style={{ textAlign: "left" , marginBottom:5, marginTop:5,}}>  {Orders} </Text> */}
                        <Text fontWeight="bold" style={{ textAlign: "center" , marginBottom:5, marginTop:5,}}>  Total : ₱ {totalPayment}</Text>
                        <Text fontWeight="bold" style={{ textAlign: "center" , marginBottom:5, marginTop:5,}}>  Payment Method: {paymentMethod}</Text>
                        <Text fontWeight="bold" style={{ textAlign: "center" , marginBottom:5, marginTop:5,}}>  Gcash Ref. No. : {GREFNo}</Text>
                        <Text fontWeight="bold" style={{ textAlign: "center" , marginBottom:5, marginTop:5,}}>  Status: {Status}</Text>

                        {/* <Button text="Update Order Status" color='#ffa500' style={{marginTop: 20}} onPress={()=> Update_status()}/> */}

                      </SectionContent>
                    
                    </Section>
            </SectionContent> 
  
        </Section>
        </View>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  containerEDT: {
    flex: 1,
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    marginTop: 120,
    flexDirection: "column",
    marginBottom: 10,
    borderRadius: 10,
    borderWidth: 10,
    backgroundColor: "rgba (0, 0, 0, 0.5)",
    borderColor: "#FFD700",
    width: "90%",
    height: "90%",
  },
  });
