import React, { useEffect, useState } from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { AdminStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../../initSupabase";
import {
  Layout,
  Button,
  Text,
  TopNav,
  Section,
  SectionContent,
  useTheme,
  themeColor,
  SectionImage,
  Picker,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";




import { LogBox } from 'react-native';
import _ from 'lodash';

LogBox.ignoreLogs(['Warning:...']); // ignore specific logs
LogBox.ignoreAllLogs(); // ignore all logs
const _console = _.clone(console);
console.warn = message => {
if (message.indexOf('Setting a timer') <= -1) {
   _console.warn(message);
   }
};
export default function ({
  navigation,
}: NativeStackScreenProps<AdminStackParamList, "AdminTabs">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState(null)
  const [Orders, setOrders] = useState(null)


  useEffect(()=>{
    const fetchOders= async () => { 
    const {data, error} = await supabase
      .from('Orders')
      .select()
      .eq( 'status', 'Pending')
      if (error){ 
        setFetchError(null)
        setOrders(null)
      } 
      if (data){
        setOrders(data)

        setFetchError(null)
      }
    }
    fetchOders()
    }, [])
    
    const filter = async () => {
      setLoading(true)
      const { data, error } = await supabase
      .from('Orders')
      .select()
      .eq('branch', branch)
      setLoading(false)
    if(data){
      console.log(data)
      setOrders(data)
      alert("Succesfull")
    }
    }

    const Update_status = async (menus:any) => {
      setLoading(true)
      const { data, error } = await supabase
      .from('Orders')
      .update({ status: "Processing" , Cancellation: "Not Eligible" })
      .eq('id', menus.id)
      setLoading(false)
    if(data){
      console.log(data)
      alert("Succesfull")
    }
    }
    const [branch, setbranch] = useState(null);
    const items1 = [
        { label: 'Calapan Branch', value: 'Calapan' },
        { label: 'Naujan Branch', value: 'Naujan' },
    ];

    
  return (
    <Layout>
      <ScrollView>
      <TopNav
        middleContent="O U T G O I N G  O R D E R S"
        // rightContent={
        //   <Ionicons name="cart" size={24}  
        //   color={isDarkmode ? themeColor.white100 : themeColor.dark} />
        // }
        rightAction={() => {
          //navigation.navigate();
        }}
      />
      <View style={styles.container}>
      <View style={styles.card}>
      <View style={{flex: 1,}}>
                <SectionContent >
                <Text fontWeight="bold" >Branch Option</Text>
                            <Picker
                            
                              items={items1}
                              value={branch}
                              placeholder="Choose Branch"
                              onValueChange={(val) => setbranch(val)}
                          />
                           <Button text="Filter" color='#FFD700' textStyle={{color: "black",
                            textTransform: "uppercase", // Set text to uppercase
                            }} 
        style={{marginTop: 20}} onPress={()=> filter()}/>
                  </SectionContent>
        <Section>
              {Orders && ( 
              <SectionContent >
                  {Orders.map((menus:any) => (          
                    <Section  key={menus.id}> 
                    <TouchableOpacity onPress={()=> {navigation.navigate("OrderItem",{OrderID: menus.id});}}>
                        <SectionContent style={ styles.singleItem}>
                        <Text size='lg'>Order No: {menus.orderID}</Text>
                        <Text size='xl'>Customer Name: {menus.name}</Text>
                        <Text size='xl'>Branch: {menus.branch}</Text>
                        <Text size='lg'>Order Status: {menus.status}</Text>
                        <Text size='md' style={{marginTop:5}}>Tap for more info.</Text>
                        <Button text="Accept Order" color='#FFD700' textStyle={{color: "black",
                        textTransform: "uppercase",}}style={{marginTop: 20}} onPress={()=> Update_status(menus)}/>
                      </SectionContent>
                      </TouchableOpacity>
                    
                    </Section>
                  ))}
            </SectionContent> )} 
        </Section>
        </View>
        </View> 
      </View>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  singleItem: {
    flex: 1,
    flexDirection: "column",
    marginBottom: 10,
    backgroundColor: '#EEEEEE',
    width: 700,
  },
  container: {
    flex: 1,
    alignItems: "center", // Center horizontally
    justifyContent: "center", // Center vertically
    backgroundColor: "", // Background color for the entire screen
  },
  card: {
    borderWidth: 10,
    borderColor: "#FFD700",
    backgroundColor: "white", // Background color for the card
    borderRadius: 10, // Border radius for the card
    shadowColor: "black", // Shadow color
    shadowOffset: {
      width: 10, // Increase the width to make the card larger
      height: 5, // Increase the height to make the card larger
    },
    shadowOpacity: 0.25, // Shadow opacity
    shadowRadius: 5, // Shadow radius
    elevation: 5, // Android shadow elevation
    marginBottom: 10,
      marginTop: 50,
      padding: 20, // Increase the padding to make the card larger
      alignItems: "center", // Center card content horizontally
      width: 800,
    },
 
  });
