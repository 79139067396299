import React, { useEffect, useState } from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { AdminStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../../initSupabase";
import {
  Layout,
  Button,
  Text,
  TopNav,
  Section,
  SectionContent,
  useTheme,
  themeColor,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";


function splitArrayIntoRows(array, itemsPerRow) {
  return array.reduce((rows, item, index) => {
    const rowIndex = Math.floor(index / itemsPerRow);
    if (!rows[rowIndex]) {
      rows[rowIndex] = [];
    }
    rows[rowIndex].push(item);
    return rows;
  }, []);
}

export default function ({
  navigation,
}: NativeStackScreenProps<AdminStackParamList, "AdminTabs">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [menu, setMenu] = useState(null);

  useEffect(() => {
    const fetchMenu = async () => {
      const { data, error } = await supabase.from("Menu").select();
      if (error) {
        setFetchError(null);
        setMenu(null);
      }
      if (data) {
        setMenu(data);
        setFetchError(null);
      }
    };
    fetchMenu();
  }, []);

  const menuRows = menu ? splitArrayIntoRows(menu, 2) : [];

  return (
    <Layout>
      <ScrollView>
        <TopNav
          middleContent="FOOD MENU"
          leftContent={
            <Ionicons
              name="chevron-back"
              size={20}
              color={isDarkmode ? themeColor.white100 : themeColor.dark}
            />
          }
          leftAction={() => navigation.goBack()}
        />
        <View style={styles.container}>
          <View style={styles.card}>
            <Section>
              <Button
                text="Add Item"
                color="#FFD700"
                textStyle={{ color: "black", textTransform: "uppercase" }}
                onPress={() => {
                  navigation.navigate("MenuAdd");
                }}
              />
              {menuRows.map((row, index) => (
                <SectionContent key={index} style={styles.rowContainer}>
                  {row.map((menus: any, menuIndex) => (
                    <TouchableOpacity
                      key={menus.id}
                      onPress={() => {
                        navigation.navigate("MenuEdit", { MenuID: menus.id });
                      }}
                      style={styles.singleItem}
                    >
                      <Text size={18} style={styles.centerText}>
                        {menus.menu_name}
                      </Text>
                      <Text size={16} style={styles.centerText}>
                        ₱ {menus.price}
                      </Text>
                      <Text size={14} style={styles.centerText}>
                        Description: {menus.discription}
                      </Text>
                      <Text size={14} style={styles.centerText}>
                        Availability: {menus.availability}
                      </Text>
                    </TouchableOpacity>
                  ))}
                </SectionContent>
              ))}
            </Section>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  singleItem: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#FFD700",
    width: 120,
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    textAlign: "center",
    marginRight:10,
    alignItems: "center"
  },
  rowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "",
  },
  card: {
    borderWidth: 10,
    borderColor: "#FFD700",
    backgroundColor: "white", // Background color for the card
    borderRadius: 10, // Border radius for the card
    shadowColor: "black", // Shadow color
    width: "90%",
    shadowOffset: 
    {
      width: 10, // Increase the width to make the card larger
      height: 5, // Increase the height to make the card larger
    },
    shadowOpacity: 0.25, // Shadow opacity
    shadowRadius: 5, // Shadow radius
    elevation: 5, // Android shadow elevation
    marginBottom: 10,
      marginTop: 50,
      padding: 20, // Increase the padding to make the card larger
      alignItems: "center", // Center card content horizontally
      Width: 100,gnItems: "center",
  },
  centerText: {
    textAlign: "center",
  },
});
