import React, { useEffect, useState } from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { AdminStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../../initSupabase";
import {
  Layout,
  Button,
  Text,
  TopNav,
  Section,
  SectionContent,
  useTheme,
  themeColor,
  SectionImage,
} from "react-native-rapi-ui";
import { Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

import { LogBox } from 'react-native';
import _ from 'lodash';

LogBox.ignoreLogs(['Warning:...']); // ignore specific logs
LogBox.ignoreAllLogs(); // ignore all logs
const _console = _.clone(console);
console.warn = message => {
if (message.indexOf('Setting a timer') <= -1) {
   _console.warn(message);
   }
};
export default function ({
  navigation,
}: NativeStackScreenProps<AdminStackParamList, "AdminTabs">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState('')
  const [tot_orders, setOrders ]= useState([])
  const [ordersC, setOrdersC ]= useState([])
  const [ordersCD, setOrdersCD ]= useState([])
  const [branchCP, setBranchCP ]= useState([])

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const { data: completedOrders, error: completedError } = await supabase
          .from('Orders')
          .select()
          .eq('status', 'Completed');
  
        if (completedError) {
          setFetchError(completedError.message);
        } else {
          if (completedOrders) {
            setOrdersC(completedOrders);
          }
          setFetchError(null);
        }
  
        const { data: cancelledOrders, error: cancelledError } = await supabase
          .from('Orders')
          .select()
          .eq('status', 'Cancelled');
  
        if (cancelledError) {
          setFetchError(cancelledError.message);
        } else {
          if (cancelledOrders) {
            setOrdersCD(cancelledOrders);
          }
          setFetchError(null);
        }
  
        const { data: calapanOrders, error: calapanError } = await supabase
          .from('Orders')
          .select()
          .eq('branch', 'Calapan');
  
        if (calapanError) {
          setFetchError(calapanError.message);
        } else {
          if (calapanOrders) {
            setBranchCP(calapanOrders);
          }
        }
      } catch (error) {
        setFetchError(error.message);
      }
    };
  
    fetchOrders();
  }, []);
  
      const labels = ['Orders'];
       const data = {
        labels,
        datasets: [
          {
            label: 'Completed',
            data: [ordersC.length],
            backgroundColor: '#FFD700',
          },
          {
            label: 'Cancelled',
            data: [ordersCD.length],
            backgroundColor: '#F48200',
          },
          {
            label: 'Calapan',
            data: [branchCP.length],
            backgroundColor: '#FFB900'
          },
        ],
      };
      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Online Orders',
          },
        },
      };
      const [tot_orders1, setOrders1] = useState([]);
      const [tot_orders2, setOrders2] = useState([]);
      const [tot_orders3, setOrders3] = useState([]);
      const [tot_orders4, setOrders4] = useState([]);
      const [tot_orders5, setOrders5] = useState([]);
      const [tot_orders6, setOrders6] = useState([]);
      const [tot_orders7, setOrders7] = useState([]);
      const [tot_orders8, setOrders8] = useState([]);
      const [tot_orders9, setOrders9] = useState([]);
      const [tot_orders10, setOrders10] = useState([]);
      const [tot_orders11, setOrders11] = useState([]);
      const [tot_orders12, setOrders12] = useState([]);
      const [tot_tblOrders10, settblOrders10] = useState([]);
      const [tot_tblOrders11, settblOrders11] = useState([]);
      const [tot_tblOrders12, settblOrders12] = useState([]);
      const [startDate, setStartDate] = useState(['2023-01-01','2023-02-01','2023-03-01','2023-04-01','2023-05-01','2023-06-01','2023-07-01','2023-08-01','2023-09-01','2023-10-01','2023-11-01','2023-12-01']); // Start date for the range
      const [endDate, setEndDate] = useState(['2023-01-31','2023-02-31','2023-03-31','2023-04-31','2023-05-31','2023-06-31','2023-07-31','2023-08-31','2023-09-31','2023-10-31','2023-11-30','2023-12-31']); // End date for the range
      
      useEffect(() => {
        const fetchOrders = async () => {
          try {
            const { data: saleOnlineOrders, error: saleOnlineOrdersError } = await supabase
              .from('Orders')
              .select('total_payment')
              .gte('created_at', startDate[0]) // Greater than or equal to start date
              .lte('created_at', endDate[0]); // Less than or equal to end date
              if (saleOnlineOrdersError) {
                setFetchError(saleOnlineOrdersError.message);
              } else { 
                if (saleOnlineOrders) {
                  setOrders1(saleOnlineOrders);
                }
                setFetchError(null);
              }
              const { data: saleOnlineOrders1, error: saleOnlineOrdersError1 } = await supabase
              .from('Orders')
              .select('total_payment')
              .gte('created_at', startDate[1]) // Greater than or equal to start date
              .lte('created_at', endDate[1]); // Less than or equal to end date
              if (saleOnlineOrdersError1) {
                setFetchError(saleOnlineOrdersError1.message);
              } else {
                if (saleOnlineOrders1) {
                  setOrders2(saleOnlineOrders1);
                }
                setFetchError(null);
              }
              const { data: saleOnlineOrders2, error: saleOnlineOrdersError2 } = await supabase
              .from('Orders')
              .select('total_payment')
              .gte('created_at', startDate[2]) // Greater than or equal to start date
              .lte('created_at', endDate[2]); // Less than or equal to end date
              if (saleOnlineOrdersError2) {
                setFetchError(saleOnlineOrdersError2.message);
              } else {
                if (saleOnlineOrders2) {
                  setOrders3(saleOnlineOrders2);
                }
                setFetchError(null);
              }
              const { data: saleOnlineOrders3, error: saleOnlineOrdersError3 } = await supabase
              .from('Orders')
              .select('total_payment')
              .gte('created_at', startDate[3]) // Greater than or equal to start date
              .lte('created_at', endDate[3]); // Less than or equal to end date
              if (saleOnlineOrdersError3) {
                setFetchError(saleOnlineOrdersError3.message);
              } else {
                if (saleOnlineOrders3) {
                  setOrders4(saleOnlineOrders3);
                }
                setFetchError(null);
              }
              const { data: saleOnlineOrders4, error: saleOnlineOrdersError4 } = await supabase
              .from('Orders')
              .select('total_payment')
              .gte('created_at', startDate[4]) // Greater than or equal to start date
              .lte('created_at', endDate[4]); // Less than or equal to end date
              if (saleOnlineOrdersError4) {
                setFetchError(saleOnlineOrdersError4.message);
              } else {
                if (saleOnlineOrders4) {
                  setOrders5(saleOnlineOrders4);
                }
                setFetchError(null);
              }
              const { data: saleOnlineOrders5, error: saleOnlineOrdersError5 } = await supabase
              .from('Orders')
              .select('total_payment')
              .gte('created_at', startDate[5]) // Greater than or equal to start date
              .lte('created_at', endDate[5]); // Less than or equal to end date
              if (saleOnlineOrdersError5) {
                setFetchError(saleOnlineOrdersError5.message);
              } else {
                if (saleOnlineOrders5) {
                  setOrders6(saleOnlineOrders5);
                }
                setFetchError(null);
              }
              const { data: saleOnlineOrders6, error: saleOnlineOrdersError6 } = await supabase
              .from('Orders')
              .select('total_payment')
              .gte('created_at', startDate[6]) // Greater than or equal to start date
              .lte('created_at', endDate[6]); // Less than or equal to end date
              if (saleOnlineOrdersError6) {
                setFetchError(saleOnlineOrdersError6.message);
              } else {
                if (saleOnlineOrders6) {
                  setOrders7(saleOnlineOrders6);
                }
                setFetchError(null);
              }
              const { data: saleOnlineOrders7, error: saleOnlineOrdersError7 } = await supabase
              .from('Orders')
              .select('total_payment')
              .gte('created_at', startDate[7]) // Greater than or equal to start date
              .lte('created_at', endDate[7]); // Less than or equal to end date
              if (saleOnlineOrdersError7) {
                setFetchError(saleOnlineOrdersError7.message);
              } else {
                if (saleOnlineOrders7) {
                  setOrders8(saleOnlineOrders7);
                }
                setFetchError(null);
              }
              const { data: saleOnlineOrders8, error: saleOnlineOrdersError8 } = await supabase
              .from('Orders')
              .select('total_payment')
              .gte('created_at', startDate[8]) // Greater than or equal to start date
              .lte('created_at', endDate[8]); // Less than or equal to end date
              if (saleOnlineOrdersError8) {
                setFetchError(saleOnlineOrdersError8.message);
              } else {
                if (saleOnlineOrders8) {
                  setOrders9(saleOnlineOrders8);
                }
                setFetchError(null);
              }
              const { data: saleOnlineOrders9, error: saleOnlineOrdersError9 } = await supabase
              .from('Orders')
              .select('total_payment')
              .gte('created_at', startDate[9]) // Greater than or equal to start date
              .lte('created_at', endDate[9]); // Less than or equal to end date
              if (saleOnlineOrdersError9) {
                setFetchError(saleOnlineOrdersError9.message);
              } else {
                if (saleOnlineOrders9) {
                  setOrders10(saleOnlineOrders9);
                }
                setFetchError(null);
              }
              const { data: tblsaleOnlineOrders9, error: tblsaleOnlineOrdersError9 } = await supabase
              .from('tblOrders')
              .select('tot_amount')
              .gte('created_at', startDate[9]) // Greater than or equal to start date
              .lte('created_at', endDate[9]); // Less than or equal to end date
              if (tblsaleOnlineOrdersError9) {
                setFetchError(tblsaleOnlineOrdersError9.message);
              } else {
                if (tblsaleOnlineOrders9) {
                  settblOrders10(tblsaleOnlineOrders9);
                }
                setFetchError(null);
              }
              
            const { data: saleOnlineOrders11, error: saleOnlineOrdersError11 } = await supabase
              .from('Orders')
              .select('total_payment')
              .gte('created_at', startDate[10]) // Greater than or equal to start date
              .lte('created_at', endDate[10]); // Less than or equal to end date
      
            if (saleOnlineOrdersError11) {
              setFetchError(saleOnlineOrdersError11.message);
            } else {
              if (saleOnlineOrders11) {
                setOrders11(saleOnlineOrders11);
              }
              setFetchError(null);
            }
            const { data: tblsaleOnlineOrders11, error: tblsaleOnlineOrdersError11 } = await supabase
              .from('tblOrders')
              .select('tot_amount')
              .gte('created_at', startDate[10]) // Greater than or equal to start date
              .lte('created_at', endDate[10]); // Less than or equal to end date
              if (tblsaleOnlineOrdersError11) {
                setFetchError(tblsaleOnlineOrdersError11.message);
              } else { 
                if (tblsaleOnlineOrders11) {
                  settblOrders11(tblsaleOnlineOrders11);
                }
                setFetchError(null);
              }
            const { data: saleOnlineOrders12, error: saleOnlineOrdersError12 } = await supabase
              .from('Orders')
              .select('total_payment')
              .gte('created_at', startDate[11]) // Greater than or equal to start date
              .lte('created_at', endDate[11]); // Less than or equal to end date
              if (saleOnlineOrdersError12) {
                setFetchError(saleOnlineOrdersError12.message);
              } else {
                if (saleOnlineOrders12) {
                  setOrders12(saleOnlineOrders12);
                }
                setFetchError(null);
              }
              const { data: tblsaleOnlineOrders12, error: tblsaleOnlineOrdersError12 } = await supabase
              .from('tblOrders')
              .select('tot_amount')
              .gte('created_at', startDate[11]) // Greater than or equal to start date
              .lte('created_at', endDate[11]); // Less than or equal to end date
              if (tblsaleOnlineOrdersError12) {
                setFetchError(tblsaleOnlineOrdersError12.message);
              } else { 
                if (tblsaleOnlineOrders12) {
                  settblOrders12(tblsaleOnlineOrders12);
                }
                setFetchError(null);
              }
          } catch (error) {
            setFetchError(error.message);
          }
        };
      
        fetchOrders();
      }, [startDate, endDate]); // Include startDate and endDate in the dependency array

      const OctSale = tot_orders10.map((item) => item.total_payment).reduce((prev, next) => prev + next, 0) + tot_tblOrders10.map((item) => item.tot_amount).reduce((prev, next) => prev + next, 0);
      const NovSale = tot_orders11.map((item) => item.total_payment).reduce((prev, next) => prev + next, 0) + tot_tblOrders11.map((item) => item.tot_amount).reduce((prev, next) => prev + next, 0);
      const DecSale = tot_orders12.map((item) => item.total_payment).reduce((prev, next) => prev + next, 0) + tot_tblOrders12.map((item) => item.tot_amount).reduce((prev, next) => prev + next, 0);

      const labels3 = ['Monthly Sale'];
      const data3 = {
       labels : labels3,
       datasets: [
        {
          label: 'January',
          data: [],
          backgroundColor: '#FFD700',
        },
        {
          label: 'February',
          data: [],
          backgroundColor: '#FFD720',
        },
        {
          label: 'March',
          data: [],
          backgroundColor: '#FFD730',
        },
        {
          label: 'April',
          data: [],
          backgroundColor: '#FFD740',
        },
        {
          label: 'May',
          data: [],
          backgroundColor: '#FFD750',
        },
        {
          label: 'June',
          data: [], 
          backgroundColor: '#FFD760',
        },
        {
          label: 'July',
          data: [],
          backgroundColor: '#FFD770',
        },
        {
          label: 'August',
          data: [],
          backgroundColor: '#FFD780',
        },
        {
          label: 'September',
          data: [],
          backgroundColor: '#FFD790',
        },
         {
           label: 'October',
           data: [OctSale],
           backgroundColor: '#FFD710',
         },
        {
           label: 'November ',
           data: [NovSale],
           backgroundColor: '#F48211',
         },
         {
           label: 'December',
           data: [DecSale],
           backgroundColor: '#FFB912'
         },
        
       ],
     };
     const options3 = {
       responsive: true,
       plugins: {
         legend: {
           position: 'top' as const,
         },
         title: {
           display: true,
           text: 'Monthly Sale for Online And Dine in Orders',
         },
       },
     };


     const [tot_ordersYr1, setOrdersYr1] = useState([]);
     const [tot_ordersYr2, setOrdersYr2] = useState([]);
     const [tot_tblOrdersYr1, settblOrdersYr1] = useState([]);
      const [tot_tblOrdersYr2, settblOrdersYr2] = useState([]);
     const [startYear, setStartYear] = useState(['2023-01-01', '2024-01-01']); // Start date for the range
     const [endYear, setEndYear] = useState(['2023-12-31','2024-12-31']); // End date for the range
     useEffect(() => {
      const fetchOrders = async () => {
        try {
            const { data: saleOnlineOrders1, error: saleOnlineOrdersError1 } = await supabase
            .from('Orders')
            .select('total_payment')
            .gte('created_at', startYear[0]) // Greater than or equal to start date
            .lte('created_at', endYear[0]); // Less than or equal to end date
            if (saleOnlineOrdersError1) {
              setFetchError(saleOnlineOrdersError1.message);
            } else {
              if (saleOnlineOrders1) {
                setOrdersYr1(saleOnlineOrders1);
              }
              setFetchError(null);
            }
            const { data: tblsaleOnlineOrders1, error: tblsaleOnlineOrdersError1 } = await supabase
            .from('tblOrders')
            .select('tot_amount')
            .gte('created_at', startYear[0]) // Greater than or equal to start date
            .lte('created_at', endYear[0]); // Less than or equal to end date
            if (tblsaleOnlineOrdersError1) {
              setFetchError(tblsaleOnlineOrdersError1.message);
            } else {
              if (tblsaleOnlineOrders1) {
                settblOrdersYr1(tblsaleOnlineOrders1);
              }
              setFetchError(null);
            }
            const { data: saleOnlineOrders2, error: saleOnlineOrdersError2 } = await supabase
            .from('Orders')
            .select('total_payment')
            .gte('created_at', startYear[1]) // Greater than or equal to start date
            .lte('created_at', endYear[1]); // Less than or equal to end date
            if (saleOnlineOrdersError2) {
              setFetchError(saleOnlineOrdersError2.message);
            } else {
              if (saleOnlineOrders2) {
                setOrdersYr2(saleOnlineOrders2);
              }
              setFetchError(null);
            }
          } catch (error) {
            setFetchError(error.message);
          }
        };
      
        fetchOrders();
      }, [startDate, endDate]); // Include startDate and endDate in the dependency array
      
    const  Yr2023  = tot_ordersYr1.map((item) => item.total_payment).reduce((prev, next) => prev + next, 0) + tot_tblOrdersYr1.map((item) => item.tot_amount).reduce((prev, next) => prev + next, 0);  
   const labels4 = [' Yearly Sale For Online And Dine in Orders'];
      const data4 = {
       labels : labels4,
       datasets: [
        {
          label: '2023',
          data: [Yr2023],
          backgroundColor: '#FFD700',
        },
        {
          label: '2024',
          data: [tot_ordersYr2.map((item) => item.total_payment).reduce((prev, next) => prev + next, 0),],
          backgroundColor: '#FFD720',
        },
     
       ],
     };
     const options4 = {
       responsive: true,
       plugins: {
         legend: {
           position: 'top' as const,
         },
         title: {
           display: true,
           text: 'Yearly Sale Orders',
         },
       },
     };



//===============================================================================Table Sale================================================================================
const [table, setTable] = useState([]);
const [table1, setTable1] = useState([]);
const [table2, setTable2] = useState([]);
const [table3, setTable3] = useState([]);
const [table4, setTable4] = useState([]);
const [table5, setTable5] = useState([]);
const [table6, setTable6] = useState([]);

useEffect(() => {
  const fetchData = async () => {
    try {
      const { data: dataTable, error: errorTable } = await supabase
        .from('tblOrders')
        .select()
        .eq('status', 'Completed');

      if (errorTable) {
        setFetchError(errorTable.message);
      } else {
        if (dataTable) {
          setTable(dataTable);
        }
      }
      const { data: dataTable1 , error: errorTable1 } = await supabase
        .from('tblOrders')
        .select()
        .eq('table', 'Table 1');

      if (errorTable1) {
        setFetchError(errorTable1.message);
      } else {
        if (dataTable1) {
          setTable1(dataTable1);
        }
      }
      const { data: dataTable2, error: errorTable2 } = await supabase
        .from('tblOrders')
        .select()
        .eq('table', 'Table 2');
      if (errorTable2) {
        setFetchError(errorTable2.message);
      } else {
        if (dataTable2) {
          setTable2(dataTable2);
        }
      }
      const { data: dataTable3, error: errorTable3 } = await supabase
        .from('tblOrders')
        .select()
        .eq('table', 'Table 3');
        if (errorTable3) {
          setFetchError(errorTable3.message);
        }else {
          if (dataTable3) {
            setTable3(dataTable3);
          }
        }
        const { data: dataTable4, error: errorTable4 } = await supabase
        .from('tblOrders')
        .select()
        .eq('table', 'Table 4');
        if (errorTable4) {
          setFetchError(errorTable4.message);
        }else {
          if (dataTable4) {
            setTable4(dataTable4);
          }
        }
        const { data: dataTable5, error: errorTable5 } = await supabase
        .from('tblOrders')
        .select()
        .eq('table', 'Table 5');
        if (errorTable5) {
          setFetchError(errorTable5.message);
        } else {
          if (dataTable5) {
            setTable5(dataTable5);
          }
        }
        const { data: dataTable6, error: errorTable6 } = await supabase
        .from('tblOrders')
        .select()
        .eq('table', 'Table 6');
        if (errorTable6) {
          setFetchError(errorTable6.message);
        } else {
          if (dataTable6) {
            setTable6(dataTable6);
          }
        }
    } catch (error) {
      setFetchError(error.message);
    }
  };

  fetchData();
}, []);

  const labels1 = ['Table Orders'];
  const data1 = {
    labels: labels1,
    datasets: [
      {
        label: 'All Table',
        data: [table.length],
        backgroundColor: '#FFB900',
      },
      {
        label: 'Table 1',
        data: [table1.length],
        backgroundColor: '#FFB900',
      },
      {
        label: 'Table 2',
        data: [table2.length],
        backgroundColor: '#F48200',
      },
      {
        label: 'Table 3',
        data: [table3.length],
        backgroundColor: '#D78F00',
      },
      {
        label: 'Table 4',
        data: [table4.length],
        backgroundColor: '#FFD720',
      },
      {
        label: 'Table 5',
        data: [table5.length],
        backgroundColor: '#FFD730',
      },
      {
        label: 'Table 6',
        data: [table6.length],
        backgroundColor: '#FFD740',
      },
    ],
  };
  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Number of Table Orders',
      },
    },
  };


const [saletable, setsaleTable] = useState([]);
const [saletable1, setsaleTable1] = useState([]);
const [saletable2, setsaleTable2] = useState([]);
const [saletable3, setsaleTable3] = useState([]);
const [saletable4, setsaleTable4] = useState([]);
const [saletable5, setsaleTable5] = useState([]);
const [saletable6, setsaleTable6] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: dataTable, error: errorTable } = await supabase
          .from('tblOrders')
          .select('tot_amount')
          .eq('status', 'Completed');
  
        if (errorTable) {
          setFetchError(errorTable.message);
        } else {
          if (dataTable) {
            setsaleTable(dataTable);
          }
        }
        const { data: dataTable1 , error: errorTable1 } = await supabase
          .from('tblOrders')
          .select('tot_amount')
          .eq('table', 'Table 1');
          if (errorTable1) {
            setFetchError(errorTable1.message);
          } else {
            if (dataTable1) {
              setsaleTable1(dataTable1);
            }
          }
          const { data: dataTable2, error: errorTable2 } = await supabase
          .from('tblOrders')
          .select('tot_amount')
          .eq('table', 'Table 2');
          if (errorTable2) {
            setFetchError(errorTable2.message);
          } else {
            if (dataTable2) {
              setsaleTable2(dataTable2);
            }
          }
          const { data: dataTable3, error: errorTable3 } = await supabase
          .from('tblOrders')
          .select('tot_amount')
          .eq('table', 'Table 3');
          if (errorTable3) {
            setFetchError(errorTable3.message);
          } else {
            if (dataTable3) {
              setsaleTable3(dataTable3);
            }
          }
          const { data: dataTable4, error: errorTable4 } = await supabase
          .from('tblOrders')
          .select('tot_amount')
          .eq('table', 'Table 4');
          if (errorTable4) {
            setFetchError(errorTable4.message);
          } else {
            if (dataTable4) {
              setsaleTable4(dataTable4);
            }
          }
          const { data: dataTable5, error: errorTable5 } = await supabase
          .from('tblOrders')
          .select('tot_amount')
          .eq('table', 'Table 5');
          if (errorTable5) {
            setFetchError(errorTable5.message);
          } else {
            if (dataTable5) {
              setsaleTable5(dataTable5);
            }
          }
          const { data: dataTable6, error: errorTable6 } = await supabase
          .from('tblOrders')
          .select('tot_amount')
          .eq('table', 'Table 6');
          if (errorTable6) {
            setFetchError(errorTable6.message);
          } else {
            if (dataTable6) {
              setsaleTable6(dataTable6);
            }
          }
      } catch (error) {
        setFetchError(error.message);
      }
    };
  
    fetchData();
  }, []); 

  const labels2 = ['Sale Table Orders'];
  const data2 = {
    labels: labels2,
    datasets: [
      {
        label: 'All Table: ₱',
        data: [saletable.map((item) => item.tot_amount).reduce((prev, next) => prev + next, 0)],
        backgroundColor: '#FFB900',
      },
      {
        label: 'Table 1: ₱',
        data: [saletable1.map((item) => item.tot_amount).reduce((prev, next) => prev + next, 0)],
        backgroundColor: '#FFB900',
      },
      {
        label: 'Table 2: ₱',
        data: [saletable2.map((item) => item.tot_amount).reduce((prev, next) => prev + next, 0)],
        backgroundColor: '#F48200',
      },
      {
        label: 'Table 3: ₱',
        data: [saletable3.map((item) => item.tot_amount).reduce((prev, next) => prev + next, 0)],
        backgroundColor: '#D78F00',
      },
      {
        label: 'Table 4: ₱',
        data: [saletable4.map((item) => item.tot_amount).reduce((prev, next) => prev + next, 0)],
        backgroundColor: '#FFD720',
      },
      {
        label: 'Table 5: ₱',
        data: [saletable5.map((item) => item.tot_amount).reduce((prev, next) => prev + next, 0)],
        backgroundColor: '#FFD730',
      },
      {
        label: 'Table 6: ₱',
        data: [saletable6.map((item) => item.tot_amount).reduce((prev, next) => prev + next, 0)],
        backgroundColor: '#FFD740',
      },
    ],
  };
  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Sale/Table Orders',
      },
    },
  };

  const print = () => { 
    window.print();
  }

  return (
    <Layout>
      <ScrollView>
      <TopNav
        middleContent="D A S H B O A R D"
        // rightContent={
        //   <Ionicons name="md-menu" size={24}  
        //   color={isDarkmode ? themeColor.white100 : themeColor.dark} />
        // }
        // rightAction={() => {
        //   navigation.navigate();
        // }}
      />
      <View style={{flex: 1,}}>
        <Section>
          <SectionContent> 
          <Button
              leftContent={<MaterialCommunityIcons name="food-variant" size={20} color={isDarkmode ? themeColor.white100 : themeColor.dark} />}
              text="   Food Menu"
              inverseColor="black"
              type="TouchableOpacity"
              color="#FFD700"
              onPress={() => {navigation.navigate("ItemMenu");}}
              style={{marginTop: 10}}
              />
            <Button
              leftContent={<MaterialIcons name="inventory" size={20} color={isDarkmode ? themeColor.white100 : themeColor.dark} />}
              text="   Inventory Menu"
              inverseColor="black"
              type="TouchableOpacity"
              color="#FFD700"
              onPress={() => {navigation.navigate("InventoryMenu");}}
              style={{marginTop: 10}}
              />
              <Button
              text="Logout"
              inverseColor="black"
              type="TouchableOpacity"
              color="#FFD700"
              onPress={async () => {
                const { error } = await supabase.auth.signOut();
                if (!error) {
                  alert("Signed out!");
                }
                if (error) {
                  alert(error.message);
                }
              }}
              style={{
                marginTop: 10,
              }}
            />
             <Button
              text="Print"
              inverseColor="black"
              type="TouchableOpacity"
              color="#FFD700"
              onPress={() => {navigation.navigate("Print");}}
              style={{
                marginTop: 10,
              }}
            />
            </SectionContent>
            <SectionContent>
            <Bar data={data} options={options}/>
            <Bar data={data3} options={options3}/>
            <Bar data={data4} options={options4}/>
            <Bar data={data1} options={options1} />
            <Bar data={data2} options={options2} />
            </SectionContent>
        </Section>
       
      </View>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  singleItem: {
    flex: 1,
    flexDirection: "column",
    marginBottom: 10,
    backgroundColor: '#FFD700',
  }

 
  });
