import React, { useEffect, useState } from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity,ImageBackground } from "react-native";
import { AdminStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../../initSupabase";
import {
  Layout,
  Button,
  Text,
  TopNav,
  Section,
  SectionContent,
  useTheme,
  themeColor,
  SectionImage,
} from "react-native-rapi-ui";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";




import { LogBox } from 'react-native';
import _ from 'lodash';

LogBox.ignoreLogs(['Warning:...']); // ignore specific logs
LogBox.ignoreAllLogs(); // ignore all logs
const _console = _.clone(console);
console.warn = message => {
if (message.indexOf('Setting a timer') <= -1) {
   _console.warn(message);
   }
};
export default function ({
  navigation,
}: NativeStackScreenProps<AdminStackParamList, "AdminTabs">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState(null)
  const [Orders, setOrders] = useState([])
  const ordrL = Orders.length

  useEffect(()=>{
    const fetchOders= async () => { 
    const {data, error} = await supabase
      .from('Orders')
      .select()
      .eq( 'status', 'Cancelled')
      if (error){ 
        setFetchError(null)
      } 
      if (data){
        setOrders(data)
        setFetchError(null)
      }
    }
    fetchOders()
    }, []);
    const renderOrderRow = (row) => (
      <View style={styles.rowContainer} key={row[0].id}>
        {row.map((order) => (
          <TouchableOpacity
            key={order.id}
            onPress={() => {
              navigation.navigate("OrderItem", { OrderID: order.id });
            }}
            style={styles.itemContainer}
          >
            <SectionContent style={styles.singleItem}>
              <Text size="lg">Order No: {order.orderID}</Text>
              <Text size="xl">Customer Name: {order.name}</Text>
              <Text size="lg">Order Status: {order.status}</Text>
              <Text size='md'>Tap for more info.</Text>
            </SectionContent>
          </TouchableOpacity>
        ))}
      </View>
    );


  return (
    <Layout>
      <ScrollView>
      <TopNav
        middleContent="COMPLETED ORDERS"
        rightAction={() => {
        }}
      />
      <View>
      <Section>
            <SectionContent style={styles.Item}>
                <Text
                  fontWeight="bold"
                  style={{ fontSize: 20 }}
                >
                  <MaterialCommunityIcons
                    name="clipboard"
                    size={20}
                    color={isDarkmode ? themeColor.white100 : themeColor.dark}
                  />
                  Cancelled Orders: {Orders.length}
                </Text>
              </SectionContent>
              {Orders && (
                <>
                  <SectionContent>
                    <Text fontWeight="bold" size="xl" style={{color: "black", marginTop: 10}}>
                      Online Orders
                    </Text>
                  </SectionContent>
                  <SectionContent>
                    {Orders.reduce((rows, order, index) => {
                      if (index % 2 === 0) {
                        rows.push([order]);
                      } else {
                        rows[rows.length - 1].push(order);
                      }
                      return rows;
                    }, []).map(renderOrderRow)}
                  </SectionContent>
                </>
              )}
            </Section>
      </View>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
    alignItems: "center",
  },
  Item:{
    backgroundColor: "#FFD700",
    textAlign: "center",
  },
  rowContainer: {
    flexDirection: "row",
    marginBottom: 10,
  },
  itemContainer: {
    flex: 1,
    marginRight: 10,
  },
  singleItem: {
    textAlign: "center",
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#FFD700",
    padding: 10,
    borderRadius:10,
    alignItems: "center",
    justifyContent: "center",
  },
});
