import React, { useEffect, useState } from "react";
import { View, ImageBackground, StyleSheet, Dimensions, ScrollView, TouchableOpacity,} from "react-native";
import { AdminStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../../initSupabase";
import {
  Layout,
  Button,
  Text,
  TopNav,
  Section,
  SectionContent,
  useTheme,
  themeColor,
  SectionImage,
  TextInput,
  Picker,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";
import { Route, RouteProp, useRoute } from "@react-navigation/native";


export default function ({
  navigation,
}: NativeStackScreenProps<AdminStackParamList, "MenuEdit">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState(null)
  const [menu, setMenu] = useState(null)

  const route = useRoute<RouteProp<AdminStackParamList, "MenuEdit">>();
  const { MenuID } = route.params;

  const [menuname, setMenuname] = useState("")
  const [price, setPrice] = useState("")
  const [Description, setDescription] = useState("")


  useEffect(()=>{
    const fetchItem = async () => { 
    const {data, error} = await supabase
      .from('Menu')
      .select()
      .eq('id',MenuID)
      .single()
      if (error?.message === 'Record not found'){ 
        setFetchError(null)
        setMenuname("")
        setDescription("")
        setAvailability("")
      } 
      if (data){
        setMenuname(data.menu_name)
        setPrice(data.price)
        setDescription(data.discription)
        setAvailability(data.availability)
        setFetchError(null)
        console.log(data)
      }
    }
    fetchItem()
    }, [MenuID])


    const Update = async () => {
   
      setLoading(true)
      const { data,error } = await supabase
      .from('Menu')
      .update([{ menu_name: menuname, price: price, discription: Description, availability: Availability,Category:Category}])
      .eq('id', MenuID)
    if (error) alert(error.message)
      setLoading(false)
    if(data){
      console.log(data)
      alert("Succesfull")
    }
    }
    console.log(route.params.MenuID)

    const [Availability, setAvailability] = useState("");
    const items = [
        { label: 'Available', value: 'Available' },
        { label: 'Unavailable', value: 'Unavailable' },
    ]
    const [Category, setCategory] = useState(null);
    const items1 = [
        { label: 'Mains', value: 'Mains' },
        { label: 'Combos', value: 'Combos' },
        { label: 'Soup', value: 'Soup' },
        { label: 'Pansit', value: 'Pansit' },
        { label: 'Seafood Bilao', value: 'Seafood Bilao' },

    ];
 
  return (
    <Layout>
      <ScrollView>
      <TopNav
        middleContent={menuname}
        leftContent={
          <Ionicons
            name="chevron-back"
            size={20}
            color={isDarkmode ? themeColor.white100 : themeColor.dark}
          />
        }
        leftAction={() => navigation.goBack()}
       
      />
      <View // style={{ flex: 1, }}
      >
        <ImageBackground
              source={require("../../../assets/images/seafoodtwo.jpg")}
              style={styles.backgroundImage}
              >
        <View style={styles.container}>
          <View style={styles.card}>
        <Section>
              <SectionContent  >
                    <Section  > 
                        <SectionContent style={styles.containerEDT}>
                        <Text fontWeight="bold" style={{ textAlign: "left" , marginBottom:5, marginTop:5,}}>  Menu Name:</Text>
                        <TextInput 
                          placeholder="Enter your Menu Name"
                          value={menuname}
                          onChangeText={(val) => setMenuname(val)}
                        />
                         <Text fontWeight="bold" style={{ textAlign: "left" , marginBottom:5,marginTop:5,}}>  Price:</Text>
                         <TextInput 
                          placeholder="Enter your Price"
                          value={price}
                          onChangeText={(val) => setPrice(val)}
                        />
                         <Text fontWeight="bold" style={{ textAlign: "left" , marginBottom:5,marginTop:5,}}>  Description:</Text>
                         <TextInput 
                          placeholder="Enter your Description"
                          value={Description}
                          onChangeText={(val) => setDescription(val)}
                        />
                         <Text fontWeight="bold" style={{ textAlign: "left" , marginBottom:5,marginTop:5,}}>  Availability:</Text>
                         <Picker
                          items={items}
                          value={Availability}
                          placeholder="Availability"
                          onValueChange={(val) => setAvailability(val)}
                          />
                             <Text fontWeight="bold" >Category</Text>
                            <Picker
                            
                              items={items1}
                              value={Category}
                              placeholder="Choose Category"
                              onValueChange={(val) => setCategory(val)}
                          />
                          <Button text="Update" color='#FFD700' style={{marginTop: 20}} 
                          textStyle={{ color: "black",
                          textTransform: "uppercase", // Set text to uppercase
                        }}
                          onPress={()=> Update()}/>

                      </SectionContent>
                    
                    </Section>
            </SectionContent> 
  
        </Section>
      </View>
      </View>
      </ImageBackground>
      </View>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  singleItem: {
    flex: 1,
    flexDirection: "column",
    marginBottom: 10,
    backgroundColor: '#FFD700',
    Width: 100,
    borderRadius: 10
  },
  container: {
    flex: 1,
    alignItems: "center", // Center horizontally
    justifyContent: "center", // Center vertically
    backgroundColor: "", // Background color for the entire screen
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
    alignItems: "center",
    height: 809
  },
  card: {
    borderWidth: 10,
    borderColor: "#FFD700",
    backgroundColor: "white", // Background color for the card
    borderRadius: 10, // Border radius for the card
    shadowColor: "black", // Shadow color
    width: "90%",
    shadowOffset: {
      width: 10, // Increase the width to make the card larger
      height: 5, // Increase the height to make the card larger
    },
    shadowOpacity: 0.25, // Shadow opacity
    shadowRadius: 5, // Shadow radius
    elevation: 5, // Android shadow elevation
    marginBottom: 10,
      marginTop: 50,
      padding: 20, // Increase the padding to make the card larger
      alignItems: "center", // Center card content horizontally
      Width: 100,
    },
 
  });




