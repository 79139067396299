import React, { useEffect, useState } from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { AdminStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../../initSupabase";
import {
  Layout,
  Button,
  Text,
  TopNav,
  Section,
  SectionContent,
  useTheme,
  themeColor,
  SectionImage,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";




import { LogBox } from 'react-native';
import _ from 'lodash';

LogBox.ignoreLogs(['Warning:...']); // ignore specific logs
LogBox.ignoreAllLogs(); // ignore all logs
const _console = _.clone(console);
console.warn = message => {
if (message.indexOf('Setting a timer') <= -1) {
   _console.warn(message);
   }
};
export default function ({
  navigation,
}: NativeStackScreenProps<AdminStackParamList, "AdminTabs">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState(null)
  const [inventory, setInventory] = useState(null)
  
  
  useEffect(()=>{
    const fetchMenu = async () => { 
    const {data, error} = await supabase
      .from('Inventory')
      .select()
      if (error){ 
        setFetchError(null)
        setInventory(null)
      } 
      if (data){
        setInventory(data)
        setFetchError(null)
      }
    }
    fetchMenu()
    }, [])


  return (
    <Layout>
      <ScrollView>
      <TopNav
        middleContent="INVENTORY"
        leftContent={
            <Ionicons
              name="chevron-back"
              size={20}
              color={isDarkmode ? themeColor.white100 : themeColor.dark}
          
            />
          }
          leftAction={() => navigation.goBack()}
      />
      <View style={styles.container}>
        <View style={styles.card}>
      <View style={{flex: 1,}}>
        <Section>
              {inventory && ( 

              <SectionContent >
                  {inventory.map((inv:any) => (          
                    <Section  key={inv.id}> 
                    <TouchableOpacity onPress={()=> {navigation.navigate("MenuEdit",{MenuID: inv.id});}}>
                        <SectionContent style={ styles.singleItem}>
                        <Text size='xl'>Item: {inv.Item_Name} </Text>
                        <Text size='lg'>Weight: {inv.Weight} (kg)</Text>
                      </SectionContent>
                      </TouchableOpacity>
                    </Section>
                  ))}
            </SectionContent> )} 
        
            <Button text="Add Item" color='#FFD700'  textStyle={{color: "#000", textTransform: "uppercase",}} onPress={()=> {navigation.navigate("InventoryAdd");}} />
        </Section>
       
      </View>
      </View>
      </View>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  singleItem: {
    flex: 1,
    flexDirection: "column",
    marginBottom: 10,
    backgroundColor: '#FFD700',
    Width: 100,
    borderRadius: 10,
    fontWeight: 700
  },
  container: {
    flex: 1,
    alignItems: "center", // Center horizontally
    justifyContent: "center", // Center vertically
    backgroundColor: "", // Background color for the entire screen
  },
  card: {
    borderWidth: 10,
    borderColor: "#FFD700",
    backgroundColor: "white", // Background color for the card
    borderRadius: 10, // Border radius for the card
    shadowColor: "black", // Shadow color
    width: "90%",
    shadowOffset: {
      width: 10, // Increase the width to make the card larger
      height: 5, // Increase the height to make the card larger
    },
    shadowOpacity: 0.25, // Shadow opacity
    shadowRadius: 5, // Shadow radius
    elevation: 5, // Android shadow elevation
    marginBottom: 10,
      marginTop: 50,
      padding: 20, // Increase the padding to make the card larger
      alignItems: "center", // Center card content horizontally
      Width: 100,
    },
 
  });
