import React, { useState } from "react";
import { StatusBar } from "expo-status-bar";
import { Linking, StyleSheet, Text as RNText, Dimensions } from "react-native";
import {
  ScrollView,
  TouchableOpacity,
  View,
  KeyboardAvoidingView,
  Image,
  ImageBackground,
} from "react-native";
import { supabase } from "../../initSupabase";
import { AuthStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Layout,
  Text,
  TextInput,
  Button,
  useTheme,
  themeColor,
} from "react-native-rapi-ui";

export default function ({
  navigation,
}: NativeStackScreenProps<AuthStackParamList, "Login">) {
  const { isDarkmode, setTheme } = useTheme();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<string>("admin");

  async function login() {
    setLoading(true);
    const { user, error } = await supabase.auth.signIn({
      email: email,
      password: password,
    });
    if (user?.email == "admin@tambuli.com") {
      alert("Welcome Admin!");
      navigation.navigate("AdminTabs");
    }
    if (!error && !user) {
      setLoading(false);
      alert("Check your email for the login link!");
    }
    if (error) {
      setLoading(false);
      alert(error.message);
    }
  }
  return (
<KeyboardAvoidingView behavior="height" enabled style={{ flex: 1 }}>
      <Layout >
      <View >
          <ImageBackground
            resizeMode="cover"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              flex: 1,
              justifyContent: "flex-end",
            }}
            source={require("../../../assets/images/seafoodtwo.jpg")}
          />
        <View >
        <ScrollView
        >
          <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 500,
                }}
              >
          </View>
          <View
                style={{
                  flex: 3,
                  paddingHorizontal: 20,
                  paddingBottom: 231,
                }}
              >
              <Image
                  resizeMode="contain"
                  style={{
                    alignSelf: "center",
                    height: 148, // Adjust the height as needed
                    width: 200, // Adjust the width as needed
                  }}
                  source={require("../../../assets/icon.png")}
                />
            <Text
                  fontWeight="bold"
                  style={{
                    alignSelf: "center",
                    padding: 30,
                    color: "white",
                  }}
                  size="h3"
                >
            LOGIN
                </Text>
                <Text style={{ marginTop: 10, color: "white",fontWeight: "bold",}}>Email</Text>
                <TextInput
  containerStyle={{ marginTop: 15, marginBottom: 15, width: "100%" }}
  placeholder="Enter your email"
  value={email}
  autoCapitalize="none"
  autoCompleteType="off"
  autoCorrect={false}
  keyboardType="email-address"
  onChangeText={(text) => setEmail(text)}
  style={{
    backgroundColor: 'transparent', // Set the background color to transparent
  }}
/>


                <Text style={{ marginTop: 10, color: "white",fontWeight: "bold",}}>Password</Text>
                <TextInput
                  containerStyle={{ marginTop: 15, marginBottom: 15, width: "100%" }}
                  placeholder="Enter your password"
                  value={password}
                  autoCapitalize="none"
                  autoCompleteType="off"
                  autoCorrect={false}
                  secureTextEntry={true}
                  onChangeText={(text) => setPassword(text)}
                />

                <Button
                  text={loading ? "Loading" : "Continue"}
                  color="#FFD700"
                  onPress={() => {
                    login();
                  }}
                  disabled={loading}
                  textStyle={{
                    color: "black",
                    textTransform: "uppercase",
                  }}
                />
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 15,
                    justifyContent: "center",
                  }}
                >
                  <Text style={{color: "white", fontWeight: "bold", fontSize: 15,}} size="md">
                    Don't have an account?</Text>
                   
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 15,
                    justifyContent: "center",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate("Register");
                    }}
                  >
                    <Text
                      size="md"
                      fontWeight="bold"
                      style={{
                        color: "white",
                        fontSize: 15,
                        marginLeft: 5,
                      }}
                    >
                      Register here
                    </Text>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 10,
                    justifyContent: "center",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate("ForgetPassword");
                    }}
                  >
                    <Text style={{color: "white",fontSize: 15,}} size="md" fontWeight="bold">
                      Forget password
                    </Text>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 30,
                    justifyContent: "center",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      isDarkmode ? setTheme("light") : setTheme("dark");
                    }}
                  >
                    <Text
                      size="md"
                      fontWeight="bold"
                      style={{
                        marginLeft: 5,
                      }}
                    >
                      
                    </Text>
                  </TouchableOpacity>
                </View>
              
            
          </View>
        </ScrollView>
        </View>
        </View>
      </Layout>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 10,
    
  },
  card: {
    borderWidth: 10,
    borderColor: "#FFD700",
    borderRadius: 10,
    shadowColor: "black",
    maxWidth: "100%",
    shadowOffset: {
      width: 10,
      height: 5,
    },
    shadowOpacity: 0.25,
    shadowRadius: 5,
    elevation: 5,
    marginBottom: 10,
    padding: 20,
    alignItems: "center",
  },
});
