import React, { useEffect, useState } from "react";
import { View, StyleSheet, Dimensions, ScrollView, TouchableOpacity,} from "react-native";
import { MainStackParamList } from "../../types/navigation";
import { AdminStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../../initSupabase";
import {
  Layout,
  Button,
  Text,
  TopNav,
  Section,
  SectionContent,
  useTheme,
  themeColor,
  SectionImage,
  TextInput,
  Picker,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";
import { Route, RouteProp, useRoute } from "@react-navigation/native";


export default function ({
  navigation,
}: NativeStackScreenProps<AdminStackParamList, "MenuAdd">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState(null)
  const [session, setSession] = useState<Session | null>(null);
 
  
  const [ItemName, setItemName] = useState("")
  const [Quantity, setQuantity] = useState()
  const [Weight, setWeight] = useState()

 
  //const [priceTotal, setSetpricetotal] = useState()

  
    const Add_Menu = async () => {

      setLoading(true)
      const { data,error } = await supabase
      .from('Inventory')
      .insert([{ Item_Name:ItemName, Weight:Weight }])
    if (error) alert(error.message)
      setLoading(false)
    if(data){
      console.log(data)
      alert("Succesfull")
    }
    } 
    
    const [Availability, setAvailability] = useState(null);
    const items = [
        { label: 'Available', value: 'Available' },
        { label: 'Unavailable', value: 'Unavailable' },
    ]

 
  return (
    <Layout>
      <ScrollView>
      <TopNav
        middleContent="Add A Inventory Item "
        leftContent={
          <Ionicons
            name="chevron-back"
            size={20}
            color={isDarkmode ? themeColor.white100 : themeColor.dark}
          />
        }
        leftAction={() => navigation.goBack()}
       
      />
      <View // style={{ flex: 1, }}
      >
        <View style={styles.container}>
        <View style={styles.card}>
        <Section>
              <SectionContent  >
                    <Section  > 
                        <SectionContent style={styles.containerEDT}>
                        <Text fontWeight="bold" style={{ textAlign: "left" , marginBottom:5, marginTop:5,}}>  Item Name:</Text>
                        <TextInput 
                          placeholder="Enter Item Name"
                          value={ItemName}
                          onChangeText={(val) => setItemName(val)}
                        />
                         {/* <Text fontWeight="bold" style={{ textAlign: "left" , marginBottom:5,marginTop:5,}}>  Quantity:</Text>
                         <TextInput 
                          placeholder="Enter Quantity"
                          value={Quantity}
                          onChangeText={(val) => setQuantity(val)}
                        /> */}
                         <Text fontWeight="bold" style={{ textAlign: "left" , marginBottom:5,marginTop:5,}}>  Weight: (kg)</Text>
                         <TextInput 
                          placeholder="Enter Weight (kg)"
                          value={Weight}
                          onChangeText={(val) => setWeight(val)}
                        />
                        {/* <Text fontWeight="bold" style={{ textAlign: "left" , marginBottom:5,marginTop:5,}}>  Availability:</Text>
                         <Picker
                          items={items}
                          value={Availability}
                          placeholder="Availability"
                          onValueChange={(val) => setAvailability(val)}
                          /> */}
                          <Button text="Add" color='#FFD700' textStyle={{color: "#000", textTransform: "uppercase",}} style={{marginTop: 20}} onPress={()=> Add_Menu()}/>

                      </SectionContent>
                    
                    </Section>
            </SectionContent> 
  
        </Section>
      </View>
      </View>
      </View>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  singleItem: {
    flex: 1,
    flexDirection: "column",
    marginBottom: 10,
    backgroundColor: '#FFD700',
    Width: 100,
    borderRadius: 10,
    fontWeight: 700
  },
  container: {
    flex: 1,
    alignItems: "center", // Center horizontally
    justifyContent: "center", // Center vertically
    backgroundColor: "", // Background color for the entire screen
  },
  card: {
    borderWidth: 10,
    borderColor: "#FFD700",
    backgroundColor: "white", // Background color for the card
    borderRadius: 10, // Border radius for the card
    shadowColor: "black", // Shadow color
    width: "90%",
    shadowOffset: {
      width: 10, // Increase the width to make the card larger
      height: 5, // Increase the height to make the card larger
    },
    shadowOpacity: 0.25, // Shadow opacity
    shadowRadius: 5, // Shadow radius
    elevation: 5, // Android shadow elevation
    marginBottom: 10,
      marginTop: 50,
      padding: 20, // Increase the padding to make the card larger
      alignItems: "center", // Center card content horizontally
      Width: 100,
    },
 
  });
